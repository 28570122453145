@media (max-width: 600px) {
    .user-guide-container{
        top: 340px !important;
        height: 50vh !important;
    }
    .h3{
        font-size: 1.5rem;
        margin: 5px 0 !important;
    }
    .p{
        font-size: 1.2rem;
        margin: 5px 0 !important;
    }
    .h2{
        font-size: 1.8rem !important;
        line-height: 2.2rem !important;
    }
}