/* ArtifactDetail.css */
.modal-artifact-image {
    display: flex; /* Hiển thị modal dưới dạng flex */
    justify-content: center; /* Canh giữa nội dung */
    align-items: center; /* Canh giữa nội dung */
    position: fixed; /* Định vị modal cố định */
    z-index: 1; /* Đảm bảo modal hiển thị trên cùng */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Kích hoạt cuộn nếu nội dung quá lớn */
    background-color: rgba(0, 0, 0, 0.9); /* Nền tối mờ */
}
  
.modal-artifact-image__content{
    
}

.img-magnifier-container {
    position: relative;
    display: inline-block;
}
.close {
    position: absolute;
    top: 9vh;
    right: 10vw;
    color: rgb(254, 32, 232);
    font-size: 60px;
    font-weight: bold;
    cursor: pointer;
}

  