

.swiper {
  max-width: 800px;
  margin-top: -40px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-wrapper {
  width: 100%;
  height: 300px;
}

.swiper-slide {
  width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  width: 15px;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.swiper {
  --swiper-pagination-color: rgba(56, 245, 255, 1);
  --swiper-pagination-bullet-inactive-color: white;
}

.button-next,
.button-prev {
  position: absolute;
  top: 50%;
  width: 50px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(56, 245, 255, 0.2);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}
:is(.button-next, .button-prev):hover {
  background-color: rgba(56, 245, 255, 0.3);
}

.button-next {
  right: 10px;
}
.button-prev {
  left: 10px;
}
.collection-swiper-image{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
